.game-title {
  background-image: url(https://cdn.workplay.digital/assets/our-services-vector.webp);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 280px;
}

.game-button button {
  border: 3px solid #000;
}

.game-button button:hover {
  transform: translateX(5px);
  transform: translateY(0px);
  border: 3px solid #000;
}

@media (min-width: 480px) {
  .game-card {
    filter: drop-shadow(8px 8px 0px #000);
  }
  .game-card:hover {
    transform: translateX(8px);
    transform: translateY(8px);
    filter: drop-shadow(0px 0px 0px #000);
  }
}

@media (max-width: 480px) {
  .game-title {
    background-image: url(https://cdn.workplay.digital/assets/our-services-vector.webp);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 200px;
  }
}

@media (max-width: 400px) {
  .game-title {
    background-image: url(https://cdn.workplay.digital/assets/our-services-vector.webp);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 140px;
  }
}

.strategy-img-border {
  position: relative;
  border: 4px solid transparent; /* Transparent border */
  border-radius: 20px; /* Ensure border itself is rounded */
  box-shadow:
    25px 25px 116px 0px #3bb5fb1a,
    -25px -25px 116px 0px #d2c03a1a;
  z-index: 0;
  overflow: hidden; /* Clip overflowing content */
}

.strategy-img-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px; /* Same radius as container */
  padding: 4px; /* Match the border width */
  background: linear-gradient(
    127.38deg,
    #f8c859 0%,
    #ec5662 33.3%,
    #8cda5f 66.6%,
    #5d78f6 100%
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
}

.strategy-img-border img {
  border-radius: 16px;
}

.scrollbar-custom {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: orange transparent; /* Orange thumb with a transparent background */
}

.scrollbar-custom::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: transparent; /* Transparent scrollbar track */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: orange; /* Orange scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.useCase-bg {
  position: relative;
  z-index: 0;
}

.useCase-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    127.22deg,
    #7c7c7c 0%,
    rgba(68, 68, 68, 0.1) 100%
  );
  backdrop-filter: blur(2px);
  opacity: 0.2; /* Set the opacity to 20% */
  z-index: -1; /* Make sure the background stays behind the content */
}
