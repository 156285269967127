@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (min-width: 720px) {
  .animate-scroll {
    animation: scroll 40s linear infinite;
  }
}

@media (max-width: 720px) {
  .animate-scroll {
    animation: scroll 20s linear infinite;
  }
}
