.headerBackground {
  background-image: url("https://cdn-dev.workplay.digital/website-assets/landingPage/header/BgVector.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 70%;
}

.headerBtn:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}
