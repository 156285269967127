.contact-phone-wrapper .react-tel-input .form-control {
  width: 100% !important;
  border: 2px solid rgba(226, 226, 226, 0.582) !important;
  outline: none;
  height: 52px !important;
  border-radius: 6px !important;
  font-size: 1rem !important;
  margin-left: 0rem !important;
  background-color: transparent !important;
  font-weight: 500 !important;
  color: #e2e2e2 !important;
}

.contact-phone-wrapper .react-tel-input {
  margin: 0rem !important;
}

.contact-phone-wrapper .react-tel-input .flag-dropdown {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  padding: 0.3125rem !important;
  margin-left: 0px !important;
  border: 2px solid transparent !important;
  background-color: transparent !important;
}

.contact-phone-wrapper .react-tel-input:focus-within .flag-dropdown {
  border: 2px solid transparent !important;
  outline: none !important;
}

.contact-phone-wrapper .react-tel-input .form-control:focus {
  border: 0px transparent !important;
  outline: none !important;
}

@media (max-width: 640px) {
  .contact-phone-wrapper .react-tel-input .flag-dropdown {
    border: 2px solid transparent !important;
  }
  .contact-phone-wrapper .react-tel-input .form-control {
    border: 1px solid rgba(226, 226, 226, 0.582) !important;
  }
}

.contact-phone-wrapper .selected-flag,
.contact-phone-wrapper .flag {
  background-color: transparent !important;
}

.contact-phone-wrapper .country,
.contact-phone-wrapper .country-name,
.contact-phone-wrapper .dial-code {
  background-color: #151515 !important;
  color: #ffffff !important;
}

.contact-phone-wrapper .country-name {
  color: #ffffff;
}

.contact-phone-wrapper .react-tel-input .flag-dropdown:focus,
.contact-phone-wrapper .react-tel-input .flag-dropdown:hover {
  outline: none !important;
  background-color: transparent !important;
}

.contact-phone-wrapper .react-tel-input .form-control:focus {
  outline: none !important;
}

/* Display message scoped */
.contact-phone-wrapper .relative-container {
  position: relative;
}

.contact-phone-wrapper .info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.contact-phone-wrapper .react-tel-input .form-control:focus {
  border: 2px solid #f8a359 !important; /* Example focus border color */
  outline: none !important;
  box-shadow: 0 0 0 1px #f8a359 !important; /* Custom focus ring */
}
