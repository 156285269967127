.faqBg {
  background: linear-gradient(135deg, #383838 0%, #212121 100%);
}

.faq-wrapper input {
  border-color: #e2e2e22f !important;
}

.faq-wrapper input:focus {
  outline: none !important;
  border-color: #f8a359d3 !important;
}
