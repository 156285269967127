.react-tel-input .form-control {
  width: calc(100% - 0.625rem) !important;
  font-family: "DM-Sans", sans-serif !important;
  height: 44px !important;
  border-radius: 6px !important;
  font-size: 1rem !important;
  margin-left: 0.625rem !important;
  background-color: white !important;
  color: #000000 !important;
  font-weight: 500 !important;
}

.react-tel-input {
  margin: 0.375rem 0rem;
}

.react-tel-input .flag-dropdown {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  padding: 0.3125rem !important;
  border: 2px solid #b3b3b3 !important;
  background-color: rgb(231, 231, 231) !important;
}

.react-tel-input:focus-within .flag-dropdown {
  border: 2px solid black !important;
}

.react-tel-input .form-control:focus {
  border: 2px solid black !important;
}

@media (max-width: 640px) {
  .react-tel-input .flag-dropdown {
    border: 1px solid black !important;
  }
}

.selected-flag,
.flag {
  background-color: rgb(231, 231, 231) !important;
}

.country,
.country-name,
.dial-code {
  background-color: rgb(231, 231, 231) !important;
}

.country-name {
  color: black;
}

.react-tel-input .flag-dropdown:focus,
.react-tel-input .flag-dropdown:hover {
  outline: none !important;
  background-color: rgb(231, 231, 231) !important;
}

.react-tel-input .form-control:focus {
  outline: none !important;
}

/* display message */
.relative-container {
  position: relative;
}

.info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.continue-btn {
  background: linear-gradient(
    135deg,
    rgba(56, 56, 56, 0.5) 6.4%,
    rgba(56, 56, 56, 0.5) 24.4%,
    rgba(33, 33, 33, 0.5) 100%
  );
  box-shadow: 0px 2px 6px 2px #0000001a;
}

.continue-btn:hover {
  background: linear-gradient(90deg, #f2f2f2 0%, #8c8c8c 100%);
  box-shadow: 0px 2px 6px 2px #0000001a;
}
