.red {
  background: linear-gradient(180deg, #963c43 0.15%, #4e171b 99.69%);
}

.green {
  background: linear-gradient(180deg, #5c8b41 0%, #2a451a 100%);
}

.blue {
  background: linear-gradient(180deg, #40509c 0%, #1e2651 100%);
}

.orange {
  background: linear-gradient(180deg, #9d6a3e 0%, #523318 100%);
}

.gray {
  background: linear-gradient(180deg, #7a7979 0%, #414141 100%);
}
