@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@400..700&family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&family=Epilogue:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* custom toast  */
.custom-toast {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

/* checkout project frame */
.iframe-container {
  width: 120px;
  height: 80px;
  overflow: hidden;
  position: relative;
}

.gameFrame {
  width: 1000%;
  height: 1000%;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
}

.iframe-container iframe {
  transform: scale(0.1);
  transform-origin: top left;
}

.checkoutBackground {
  background-image: url("./assets/abstractLines.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 70%;
}

@property --animate-duration {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@property --Color {
  syntax: "<color>";
  initial-value: #f8a359;
}

@keyframes rotate {
  0% {
    --animate-duration: 0deg;
    --Color: #f8a359;
  }
  100% {
    --animate-duration: 360deg;
    --Color: #f8a359;
  }
}

.createbtn:after,
.createbtn:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  border-radius: calc(100px + 1.5px);
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-image: conic-gradient(
    from var(--animate-duration) at 50% 50%,
    #ffff 0%,
    #ffff 20%,
    var(--Color) 50%,
    var(--Color) 66%,
    #ffff 100%
  );
  transition: none;
}

.createbtn:hover:after,
.createbtn:hover:before {
  animation: rotate 2s infinite linear;
}

.createbtn:before {
  filter: blur(20px);
}

/*
.createbtn {
  position: relative; 
  overflow: visible; 
}

.createbtn:after,
.createbtn:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px; 
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-image: conic-gradient(
    from var(--animate-duration) at 50% 50%,
    #ffff 0%,
    #ffff 20%,
    var(--Color) 50%,
    var(--Color) 66%,
    #ffff 100%
  );
  transition: none;
}

.createbtn:hover:after,
.createbtn:hover:before {
  animation: rotate 2s infinite linear;
}

.createbtn:before {
  filter: blur(20px);
}


.sparkle1,
.sparkle2,
.sparkle3 {
  position: absolute;
  z-index: 10; 
  transition: transform 0.3s ease;
}


@keyframes sparkleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1); 
  }
}

.createbtn:hover .sparkle1 {
  animation: sparkleAnimation 1s ease-in-out infinite;
  animation-delay: 0s; 
}

.createbtn:hover .sparkle2 {
  animation: sparkleAnimation 1s ease-in-out infinite;
  animation-delay: 0.2s;
}

.createbtn:hover .sparkle3 {
  animation: sparkleAnimation 1s ease-in-out infinite;
  animation-delay: 0.4s;
}
*/

.sparkle1,
.sparkle2,
.sparkle3 {
  transition: transform 1s ease;
  position: absolute; /* Ensure positioning above content */
  z-index: 10; /* Bring the sparkles above other content */
}

/* Keyframe animations for each sparkle */
@keyframes sparkleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); /* Grow the sparkle */
  }
  100% {
    transform: scale(1); /* Shrink back to original size */
  }
}

.createbtn:hover .sparkle1 {
  animation: sparkleAnimation 2s ease-in-out infinite;
  animation-delay: 0s; /* Sparkle 1 animates first */
}

.createbtn:hover .sparkle2 {
  animation: sparkleAnimation 2s ease-in-out infinite;
  animation-delay: 1s; /* Sparkle 2 animates 1 second after Sparkle 1 */
}

.createbtn:hover .sparkle3 {
  animation: sparkleAnimation 2s ease-in-out infinite;
  animation-delay: 2s; /* Sparkle 3 animates 1 second after Sparkle 2 */
}

/* body {
  background-color: #1e1e1e;
} */

.ellipse {
  width: 650px;
  height: 180px;
  background: #8acf62;
  filter: blur(350px);
}

.win {
  background: var(
    --new-gd,
    linear-gradient(
      90deg,
      #f8c859 10.51%,
      #ec5662 40.39%,
      #8cda5f 70.27%,
      #5d78f6 80.16%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.underline-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the thickness of the underline */
  background: var(
    --new-gd,
    linear-gradient(
      90deg,
      #f8c859 10.51%,
      #ec5662 40.39%,
      #8cda5f 70.27%,
      #5d78f6 80.16%
    )
  );
  background-clip: padding-box;
  z-index: -1; /* Ensure the underline stays behind the text */
}

.underline-link {
  position: relative;
  text-decoration: none;
  background: linear-gradient(
    90deg,
    #f8c859 10.51%,
    #ec5662 40.39%,
    #8cda5f 70.27%,
    #5d78f6 80.16%
  );
  background-repeat: no-repeat;
  background-size: 100% 2px; /* Set the underline thickness */
  background-position: 0 100%; /* Position the underline */
  word-wrap: break-word;
}

.checkbox {
  height: 10px !important;
}

.WhyWorkplayBg {
  background-image: url("https://cdn-dev.workplay.digital/website-assets/landingPage/why-workplay/bgvector.webp");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center 70%; */
}

.whatWeOfferBg {
  background-image: url("https://cdn-dev.workplay.digital/website-assets/servicePage/mainBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.serviceBg {
  background-image: url("https://cdn-dev.workplay.digital/website-assets/servicePage/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 98%;
  overflow: hidden;
}

.testimonials-cardBg {
  opacity: 0.2;
  background: linear-gradient(127deg, #7c7c7c 0%, rgba(68, 68, 68, 0.1) 100%);
  filter: blur(1px);
}

/* hot-toast */

.toastify-toast {
  font-weight: 500;
  text-align: left;
}

/* menu bar open hide content */

.hidden-content {
  display: none;
}

/* tooltip */

.custom-tooltip {
  z-index: 9999;
  position: relative;
}

/* verify email */

.verify-container {
  height: calc(100vh - 82px);
}

@media (max-width: 900px) {
  .verify-container {
    height: calc(100vh - 70px);
  }
}

/* highlight */

strong::before {
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(2deg);
}

/* integration */

.integration-card {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  transition: transform 0.3s ease;
  cursor: pointer;
  max-width: 300px;
}

.integration-card:hover {
  transform: translateY(-10px);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #e60012;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 16px 0;
  background-color: #e60012;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font:
    700 16px/1 "Lato",
    sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: -10px;
  transform: translateY(-10px);
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* animation for banner */

@keyframes slide-in {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-slide-in {
  animation: slide-in 0.5s forwards;
}

.animate-spin {
  animation: spin 0.5s forwards;
}

/* input */

input,
textarea {
  border: 2px solid #b3b3b3 !important;
  font-size: 16px;
}

input::placeholder,
textarea::placeholder {
  color: rgba(112, 112, 112, 0.815);
  font-size: 15px;
}

#campaignInput:focus {
  outline: 0.5px solid rgba(0, 0, 0, 0.11);
}

#campaignInput {
  border: 1px solid #b3b3b3 !important;
  font-size: 16px;
}

#campaignInput::placeholder {
  font-size: 16px;
}

.react-datepicker-wrapper,
.datepicker {
  width: 100% !important;
}

@media (max-width: 640px) {
  input,
  textarea {
    border: 1px solid black !important;
  }
}

/* or line */

.orline::before,
.orline::after {
  content: "";
  flex: 1;
  border-top: 0.0313rem solid #e2e2e2;
  margin: 0 0.625rem;
}

#resetPass,
#forgotPass {
  background-color: black;
  min-height: calc(100vh - 90px);
  max-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* navbar */

#navbar {
  background-color: #1e1e1e;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 128px;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.nav-items li {
  list-style: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.05rem;
}

.nav-logout {
  text-decoration: underline !important;
  color: #26c83f !important;
}

.nav-items a {
  text-decoration: none;
  color: #ffffff;
}

.nav-items li:hover {
  opacity: 0.5;
}

#navbar img {
  height: 25px;
  cursor: pointer;
}

.burger {
  cursor: pointer;
  display: block;
  display: none;
}

.line {
  width: 25px;
  height: 2px;
  background-color: white;
  margin: 6px 0;
  transition: transform 0.3s ease-in-out;
}

.line.open {
  margin: 4px 0;
}

.line.open:nth-child(1) {
  transform: rotate(-45deg) translate(-4px, 4px);
}

.line.open:nth-child(2) {
  opacity: 0;
}

.line.open:nth-child(3) {
  transform: rotate(45deg) translate(-4px, -4px);
}

/* mobile responsive */

@media (max-width: 1300px) {
  #navbar {
    display: block;
    padding: 20px 0px;
  }
  #navbar img {
    margin-left: 40px;
  }
  body.mobile-menu-open .game-card {
    display: none;
  }
  .nav-items {
    width: 100%;
    height: 100vh;
    display: block;
    padding: 60px 80px;
    background-color: gray;
  }
  .nav-items li {
    text-align: center;
    color: rgb(199, 199, 199);
    font-size: 1.2rem;
    font-weight: 700;
    margin: 10px 0px;
  }
  .nav-items li:hover {
    opacity: 0.8px;
  }
  .menubar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .burger {
    margin-right: 40px;
    display: block;
  }
  .nav-items {
    display: none;
  }
  .navItems-btns {
    display: flex;
    position: static;
    background-color: transparent;
    transform: translateY(-100%);
  }
  .nav-items.open {
    display: flex;
    flex-direction: column;
    gap: 14px;
    position: fixed;
    min-height: 100vh;
    max-height: auto;
    top: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    z-index: 1;
  }
  .login-signUp button {
    margin-top: 12px;
    font-size: 1.1rem;
    width: 140px;
  }
}

@media (max-width: 500px) {
  .burger {
    display: block;
    margin-right: 40px;
  }
  #navbar img {
    height: 1.5rem;
    margin: 25px 40px;
  }
  .nav-items.open {
    top: 0px;
  }
  .nav-items li {
    margin: 5px;
    font-size: 1.2rem;
  }
  #navbar {
    padding: 0;
  }
  .login-signUp button {
    display: block;
    margin: 20px auto;
  }
}

@media (max-width: 420px) {
  .nav-items.open {
    z-index: 1000;
  }
  #footer {
    z-index: 1;
  }
}

@media (max-width: 350px) {
  #navbar img {
    margin-left: 30px;
    height: 20px;
  }
  .burger {
    margin-right: 30px;
  }
}

/* navbar */

@media (min-width: 1800px) {
  #navbar {
    padding: 25px 280px;
  }
}
