.input-wrapper input {
  height: 52px !important;
}

.input-wrapper input[type="checkbox"] {
  height: 18px !important;
}

.input-wrapper input,
.input-wrapper textarea {
  border: 2px solid rgba(226, 226, 226, 0.582) !important;
  outline: none;
  height: 52px !important;
}

.input-wrapper input::placeholder,
.input-wrapper textarea::placeholder {
  color: rgba(112, 112, 112, 0.815);
}

.input-text textarea {
  height: 100px !important;
}

.input-wrapper input:focus,
.input-wrapper textarea:focus {
  border: 0px transparent !important;
  outline: none !important;
}

@media (max-width: 640px) {
  .input-wrapper input,
  .input-wrapper textarea {
    border: 1px solid rgba(226, 226, 226, 0.582) !important;
  }
}
