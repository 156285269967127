.checkout-wrapper input {
  border-color: #6b6b6b !important;
}

.checkout-wrapper input:focus {
  outline: none !important;
  border-color: #000000 !important;
}

.checkout-wrapper input.input-error {
  border-color: red !important;
}

.checkout-wrapper input.input-error:focus {
  border-color: red !important;
}

.custom-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #000;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.custom-radio:hover {
  border-color: #444;
}

.custom-radio:checked {
  background-color: #000;
  border-color: #000;
}

.custom-radio:checked::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
}

@media (max-width: 900px) {
  .custom-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #000;
    outline: none;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in-out;
  }
  .custom-radio:checked::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
  }
}
